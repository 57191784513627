@import "./../../styles/variables.scss";

.dashboard {
  padding: 52px 0 0 0;
}
.addressBook {
  padding: 52px 0 120px 0;

  // 구매 진행하기
  .header {
    h1 {
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: normal;
      text-align: left;
      color: $color-black;
    }
    .notice {
      margin-top: 8px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: $color-gray3;
    }
  }
  .contents {
    margin-top: 40px;
    .itemWrapper {
      .contHeader {
        .item {
          display: flex;
          align-items: center;
          height: 58px;
          background-color: $color-gray7;
          border: solid $color-gray6;
          border-width: 1px 0 1px 0;
          .tdChk {
            flex-shrink: 0;
            margin-right: 6px;
            label {
              span {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: $color-black;
              }
            }
          }
          .tdAddress {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-right: 1px solid $color-gray6;
          }
          .tdTitle {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 168px;
          }
          .tdOption {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 264px;
            border-right: 1px solid $color-gray6;
          }
          .tdTit {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: normal;
            text-align: center;
            color: $color-black;
          }
        }
      }
      .itemCont {
        .itemList {
          .item {
            position: relative;
            display: flex;
            align-items: center;
            height: 120px;
            border-bottom: 1px solid $color-gray6;
            .tdAddress {
              position: relative;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            }
            .tdTitle {
              position: relative;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              width: 168px;
            }
            .tdOption {
              position: relative;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              width: 264px;

              .buttonList {
                display: flex;
                flex-direction: column;
                button:not(:last-child) {
                  margin-bottom: 16px;
                }
              }
            }
            .mainTxt {
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.78;
              letter-spacing: normal;
              text-align: left;
              color: $color-black;
            }
            .descTxt {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.75;
              letter-spacing: normal;
              text-align: center;
              color: $color-black;
            }
          }
        }
      }
      .contOption {
        display: flex;
        justify-content: center;
        gap: 12px;
        padding: 116px 0 32px 0;
        text-align: center;
        button {
        }
      }
      // 아이템 empty
      .nonItems {
        margin-top: 120px;
        text-align: center;
        .icon {
          img {
            width: 120px;
            height: 120px;
          }
        }
        p {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: center;
          color: $color-gray1;
          margin-top: 24px;
        }
      }
    }
  }
}
