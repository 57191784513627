/****************************** Design Custom ******************************/
@import "./variables.scss";

/* Typographyt */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Noto+Sans+KR:wght@300;400;500;700;900&display=swap");

* {
  font-family: "Noto Sans KR", sans-serif;
}
html,
body {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
}
.fwr {
  font-weight: 400;
}
.fwm {
  font-weight: 500;
}
.fwb {
  font-weight: 700;
}
h1 {
  font-size: 28px;
  line-height: 48px;
}
h2 {
  font-size: 24px;
  line-height: 40px;
}
h3 {
  font-size: 20px;
  line-height: 34px;
}
h4 {
  font-size: 18px;
  line-height: 32px;
}
.pl {
  font-size: 16px;
  line-height: 28px;
}
.pm {
  font-size: 14px;
  line-height: 24px;
}
.ps {
  font-size: 12px;
  line-height: 20px;
}

.heading-1 {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $color-black;
}
.heading-2 {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $color-black;

  &.fw-medium {
    font-weight: 500;
  }
}
.heading-3 {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $color-black;
}

/****************************** Layout ******************************/
.global-width {
  max-width: $container;
  min-width: $minwidth;
  margin: 0 auto;
}
.form-width {
  max-width: $formWidth;
  margin: 0 auto;
}

/****************************** UI Components ******************************/
// Button
@import "./elements/_button.scss";

// Input
@import "./elements/_input.scss";

// Selectbox
.css-1okebmr-indicatorSeparator {
  width: 0 !important;
}
.css-tlfecz-indicatorContainer {
  color: #000 !important;
}
.css-bgy71k-singleValue {
  overflow: unset !important;
}
.option_remove {
  background-color: red !important;
  color: red !important;
  font-size: 32px !important;
}

// Checkbox
.checkbox {
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  input {
    display: none;
  }
  input:checked ~ div {
    background: url("../assets/icons/checkbox_active.svg");
  }
  div {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background: url("../assets/icons/checkbox.svg");
    background-size: 24px;
    cursor: pointer;
  }
  button {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: $color-gray1;
    text-decoration: underline;
    margin-left: 16px;
  }
}

// Radio button
.radiobox {
  position: relative;
  input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  input[type="radio"] + label {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $color-gray3;
  }
  input[type="radio"]:checked + label {
    color: $color-black;
  }
  input[type="radio"] + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    text-align: center;
    background-color: #fff;
    border: 1px solid $color-gray3;
    border-radius: 100%;
  }
  input[type="radio"] + label:before,
  input[type="radio"] + label:after {
    transition: border-color 0.35s, background-color 0.35s;
  }
  input[type="radio"] + label:active:before,
  input[type="radio"]:checked + label:active:before {
  }
  input[type="radio"]:checked + label:before {
    background-color: #fff;
    border-color: $color-primary;
  }
  input[type="radio"]:checked + label:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: $color-primary;
    border-radius: 50%;
  }
}

// loader
.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: fixed;
  top: calc(50% - 80px);
  left: 50%;
  text-indent: -9999em;
  background-color: transparent;
  border-top: 0.8em solid rgba(0, 0, 0, 0.2);
  border-right: 0.8em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.8em solid rgba(0, 0, 0, 0.2);
  border-left: 0.8em solid $color-primary;
  -webkit-transform: translateZ(0) translateX(-50%) translateY(-50%);
  -ms-transform: translateZ(0) translateX(-50%) translateY(-50%);
  transform: translateZ(0) translateX(-50%) translateY(-50%);
  -webkit-animation: load 1.1s infinite linear;
  animation: load 1.1s infinite linear;
}
@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/****************************** LIBRARY ******************************/
// swiper - pagination
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 28px;
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin: 0 5.5px !important;
  opacity: 0.5;
  background-color: $color-gray4;

  &.bullet-primary {
    background-color: $color-white;
  }
}
.swiper-pagination-bullet-active {
  background-color: $color-primary;
  opacity: 1;
}
// swiper - navigation
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  opacity: 0.5;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  background-color: $color-white;
  transition: opacity 0.35s;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: 1;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../assets/icons/depth-sm-gr.svg") center center no-repeat;
  transform: rotate(180deg);
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  opacity: 0.5;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  background-color: $color-white;
  transition: opacity 0.35s;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after .swiper-button-prev:hover {
  opacity: 1;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../assets/icons/depth-sm-gr.svg") center center no-repeat;
}
// Swiper - Thumb
.swiperThumb .swiper-slide {
  width: 100%;
}
.swiperThumb img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.swiperThumb .swiper-slide-thumb-active {
  img {
    border: 3px solid $color-primary;
  }
}
.swiperThumbMain .swiper-pagination {
  width: auto;
  text-align: right;
  left: unset;
  right: 28px;
  bottom: 28px;
  padding: 3px 8px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 12px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: $color-gray4;
  .swiper-pagination-current {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: $color-white;
  }
  .swiper-pagination-total {
  }
}
.swiper-slide {
  text-align: center;
}
.swiper-slide img {
  width: 100%;
}

// MUI - Slider
.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
  box-shadow: none !important;
}
.MuiSlider-thumb {
  margin-top: -10px !important;
  width: 20px !important;
  height: 20px !important;
  background-color: $color-white !important;
  border: 2px solid $color-primary !important;
}
.MuiSlider-track {
  background-color: $color-primary !important;
}

/****************************** QUICK ******************************/
/* margin & padding */
.p-0 {
  padding: 0;
}
.p-3 {
  padding: 3px;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}
.p-30 {
  padding: 30px;
}
.p-35 {
  padding: 35px;
}
.p-40 {
  padding: 40px;
}
.p-45 {
  padding: 45px;
}
.p-50 {
  padding: 50px;
}
.p-55 {
  padding: 55px;
}
.p-60 {
  padding: 60px;
}
.p-65 {
  padding: 65px;
}
.p-70 {
  padding: 70px;
}
.p-75 {
  padding: 75px;
}
.p-80 {
  padding: 80px;
}
.p-85 {
  padding: 85px;
}
.p-90 {
  padding: 90px;
}
.p-95 {
  padding: 95px;
}
.p-100 {
  padding: 100px;
}
.pt-0 {
  padding-top: 0;
}
.pt-3 {
  padding-top: 3px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-55 {
  padding-top: 55px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-85 {
  padding-top: 85px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-95 {
  padding-top: 95px;
}
.pt-100 {
  padding-top: 100px;
}
.pr-0 {
  padding-right: 0;
}
.pr-3 {
  padding-right: 3px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-65 {
  padding-right: 65px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-75 {
  padding-right: 75px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-85 {
  padding-right: 85px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-95 {
  padding-right: 95px;
}
.pr-100 {
  padding-right: 100px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-3 {
  padding-bottom: 3px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-85 {
  padding-bottom: 85px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-95 {
  padding-bottom: 95px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pl-0 {
  padding-left: 0;
}
.pl-3 {
  padding-left: 3px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-55 {
  padding-left: 55px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-65 {
  padding-left: 65px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-75 {
  padding-left: 75px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-85 {
  padding-left: 85px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-100 {
  padding-left: 100px;
}
.m-0 {
  margin: 0;
}
.m-3 {
  margin: 3px;
}
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.m-25 {
  margin: 25px;
}
.m-30 {
  margin: 30px;
}
.m-35 {
  margin: 35px;
}
.m-40 {
  margin: 40px;
}
.m-45 {
  margin: 45px;
}
.m-50 {
  margin: 50px;
}
.m-55 {
  margin: 55px;
}
.m-60 {
  margin: 60px;
}
.m-65 {
  margin: 65px;
}
.m-70 {
  margin: 70px;
}
.m-75 {
  margin: 75px;
}
.m-80 {
  margin: 80px;
}
.m-85 {
  margin: 85px;
}
.m-90 {
  margin: 90px;
}
.m-95 {
  margin: 95px;
}
.m-100 {
  margin: 100px;
}
.mt-0 {
  margin-top: 0;
}
.mt-3 {
  margin-top: 3px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-100 {
  margin-top: 100px;
}
.mr-0 {
  margin-right: 0;
}
.mr-3 {
  margin-right: 3px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-55 {
  margin-right: 55px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-65 {
  margin-right: 65px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-75 {
  margin-right: 75px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-85 {
  margin-right: 85px;
}
.mr-90 {
  margin-right: 90px;
}
.mr-95 {
  margin-right: 95px;
}
.mr-100 {
  margin-right: 100px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-3 {
  margin-bottom: 3px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mb-100 {
  margin-bottom: 100px;
}
.ml-0 {
  margin-left: 0;
}
.ml-3 {
  margin-left: 3px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-55 {
  margin-left: 55px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-65 {
  margin-left: 65px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-75 {
  margin-left: 75px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-85 {
  margin-left: 85px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-95 {
  margin-left: 95px;
}
.ml-100 {
  margin-left: 100px;
}
/* percentage */
.w01p {
  width: 1%;
}
.w02p {
  width: 2%;
}
.w03p {
  width: 3%;
}
.w04p {
  width: 4%;
}
.w05p {
  width: 5%;
}
.w06p {
  width: 6%;
}
.w07p {
  width: 7%;
}
.w08p {
  width: 8%;
}
.w09p {
  width: 9%;
}
.w10p {
  width: 10%;
}
.w11p {
  width: 11%;
}
.w12p {
  width: 12%;
}
.w13p {
  width: 13%;
}
.w14p {
  width: 14%;
}
.w15p {
  width: 15%;
}
.w16p {
  width: 16%;
}
.w17p {
  width: 17%;
}
.w18p {
  width: 18%;
}
.w19p {
  width: 19%;
}
.w20p {
  width: 20%;
}
.w21p {
  width: 21%;
}
.w22p {
  width: 22%;
}
.w23p {
  width: 23%;
}
.w24p {
  width: 24%;
}
.w25p {
  width: 25%;
}
.w26p {
  width: 26%;
}
.w27p {
  width: 27%;
}
.w28p {
  width: 28%;
}
.w29p {
  width: 29%;
}
.w30p {
  width: 30%;
}
.w31p {
  width: 31%;
}
.w32p {
  width: 32%;
}
.w33p {
  width: 33%;
}
.w34p {
  width: 34%;
}
.w35p {
  width: 35%;
}
.w36p {
  width: 36%;
}
.w37p {
  width: 37%;
}
.w38p {
  width: 38%;
}
.w39p {
  width: 39%;
}
.w40p {
  width: 40%;
}
.w41p {
  width: 41%;
}
.w42p {
  width: 42%;
}
.w43p {
  width: 43%;
}
.w44p {
  width: 44%;
}
.w45p {
  width: 45%;
}
.w46p {
  width: 46%;
}
.w47p {
  width: 47%;
}
.w48p {
  width: 48%;
}
.w49p {
  width: 49%;
}
.w50p {
  width: 50%;
}
.w51p {
  width: 51%;
}
.w52p {
  width: 52%;
}
.w53p {
  width: 53%;
}
.w54p {
  width: 54%;
}
.w55p {
  width: 55%;
}
.w56p {
  width: 56%;
}
.w57p {
  width: 57%;
}
.w58p {
  width: 58%;
}
.w59p {
  width: 59%;
}
.w60p {
  width: 60%;
}
.w61p {
  width: 61%;
}
.w62p {
  width: 62%;
}
.w63p {
  width: 63%;
}
.w64p {
  width: 64%;
}
.w65p {
  width: 65%;
}
.w66p {
  width: 66%;
}
.w67p {
  width: 67%;
}
.w68p {
  width: 68%;
}
.w69p {
  width: 69%;
}
.w70p {
  width: 70%;
}
.w71p {
  width: 71%;
}
.w72p {
  width: 72%;
}
.w73p {
  width: 73%;
}
.w74p {
  width: 74%;
}
.w75p {
  width: 75%;
}
.w76p {
  width: 76%;
}
.w77p {
  width: 77%;
}
.w78p {
  width: 78%;
}
.w79p {
  width: 79%;
}
.w80p {
  width: 80%;
}
.w81p {
  width: 81%;
}
.w82p {
  width: 82%;
}
.w83p {
  width: 83%;
}
.w84p {
  width: 84%;
}
.w85p {
  width: 85%;
}
.w86p {
  width: 86%;
}
.w87p {
  width: 87%;
}
.w88p {
  width: 88%;
}
.w89p {
  width: 89%;
}
.w90p {
  width: 90%;
}
.w91p {
  width: 91%;
}
.w92p {
  width: 92%;
}
.w93p {
  width: 93%;
}
.w94p {
  width: 94%;
}
.w95p {
  width: 95%;
}
.w96p {
  width: 96%;
}
.w97p {
  width: 97%;
}
.w98p {
  width: 98%;
}
.w99p {
  width: 99%;
}
.w100p {
  width: 100%;
}
/* index */
.z-100 {
  z-index: 100 !important;
}
.z-105 {
  z-index: 105 !important;
}
.z-110 {
  z-index: 110 !important;
}
.z-115 {
  z-index: 115 !important;
}
.z-120 {
  z-index: 120 !important;
}



// commons
.hidePrice {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .hp1 {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  .hp2 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
}
.hidePrice2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .hp1 {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  .hp2 {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #666;
  }
}