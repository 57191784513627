@import "./../styles/variables.scss";

.container {
  min-height: calc(100vh - #{$headerHeight});
  margin-top: $headerHeight;
  background-color: #fff;
}
.appTest {
  position: fixed;
  top: 80px;
  left: 1%;
  z-index: 1000;
  color: red;
  font-size: 48px;
  font-weight: 900;
}