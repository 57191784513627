@import "./../styles/variables.scss";

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    margin-top: 191px;

    img {
      width: 100%;
    }
  }
  &__mainTxt {
    margin-top: 32px;
  }
  &__subTxt {
    margin-top: 16px;
  }
  &__buttons {
    margin-top: 59px;
  }
}
