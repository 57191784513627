@import "./../styles/variables.scss";

.modal {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    // pointer-events: none;

    &.dim {
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 100;
    }
    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      width: 600px;
      height: 660px;
      background-color: #fff;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);

      .btn_close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 14px;
        height: 14px;
        background: url("../assets/icons/close.svg") center center no-repeat;
        border: none;
        cursor: pointer;
      }
    }
  }
}
