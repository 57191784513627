@import "./../styles/variables.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  // pointer-events: none;

  &.dim {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
  }
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 360px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);

  .title {
    padding: 20px 20px 28px 20px;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: $color-black;
    padding-bottom: 8px;
  }
  .body {
    padding: 0 20px 36px 20px;
    text-align: left;
    white-space: pre-line;
    font-size: 14px;
    font-weight: 400;
    color: $color-gray1;

    p {
      max-height: 356px;
      overflow-y: scroll;
      line-height: 24px;
    }
  }
  .btn_close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 14px;
    height: 14px;
    background: url("../assets/icons/close.svg") center center no-repeat;
    border: none;
    cursor: pointer;
  }
}
