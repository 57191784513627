@import "./../../styles/variables.scss";

.dashboard {
  display: flex;
  width: 100%;
  height: 164px;
  background-color: $color-gray7;
  .info {
    display: flex;
    flex-shrink: 0;
    width: 264px;
    padding: 33px 0 0 24px;
    border-right: 2px solid $color-gray5;
    .userGrade {
      img {
        width: 80px;
        height: 80px;
      }
    }
    .userInfo {
      margin-left: 16px;
      .name {
        display: inline-flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: normal;
        text-align: left;
        color: $color-black;
        img {
          margin-left: 4px;
        }
      }
      .id {
        margin-top: 8px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: $color-gray3;
      }
      .grade {
        position: relative;
        display: inline-flex;
        align-items: center;
        margin-top: 8px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: $color-black;
        cursor: pointer;
        img {
          margin-left: 5px;
          width: 14px;
        }
        .qDesc {
          position: absolute;
          top: 50%;
          left: calc(100% + 10px);
          transform: translateY(-50%);
          width: max-content;
          white-space: nowrap;
          padding: 4px 20px;
          border-radius: 8px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          border: solid 1px $color-gray6;
          background-color: $color-white;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: left;
          color: $color-black;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.55s, top 0.55s;
        }
        &:hover .qDesc {
          // top: -45px;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 32px;
    border-right: 2px solid $color-gray5;
    .item {
      .itemTit {
        padding: 9px 9px 3px 9px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: $color-black;
      }
      .itemDesc {
        // padding: 0 0 0 9px;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: center;
        color: $color-black;
        cursor: pointer;
      }
      &:not(:last-child) {
        // margin-right: 32px;
      }
    }
  }
  .mileage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    width: 160px;
    border-right: 2px solid $color-gray5;
    .itemTit {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      color: $color-black;
      img {
        width: 24px;
        margin-right: 6px;
      }
    }
    .itemDesc {
      margin-top: 6px;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: $color-black;
      cursor: pointer;
    }
  }
  .point {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    width: 160px;
    .itemTit {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      color: $color-black;
      img {
        width: 24px;
        margin-right: 6px;
      }
    }
    .itemDesc {
      margin-top: 6px;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: $color-black;
      cursor: pointer;
    }
  }
}

.gradeInfoModal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 105;
  .infoCont {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1128px;
    max-height: calc(100vh - 80px);
    background-color: #fff;
    padding: 40px 32px 40px 40px;
    border-radius: 24px;
    overflow-y: scroll;
    .btnCloseModal {
      position: absolute; 
      top: 24px; 
      right: 24px;
      z-index: 5;
      img{
        width: 24px;
        height: 24px;
      }
    }
    .mainTit {
      font-size: 20px;
      line-height: 34px;
      font-weight: 700;
    }
    .infoHeader {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin: 20px 0 30px 0;
      padding: 20px;
      border: 1px solid #eaeaea;
      background-color: rgba(44, 71, 98, 0.03);
      .item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        width: 100%;
        .itemTit {
          font-size: 15px;
          font-weight: 700;
          color: #000000;
        }
        .itemDesc {
          font-size: 15px;
          font-weight: 400;
          color: #666666;
          word-break: break-all;
        }
        &:not(:last-child)::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          display: inline-block;
          width: 1px;
          height: 100%;
          background-color: #d7d7d7;
        }
      }
    }
    .gradeTable {
      margin-top: 20px;
      table {
        width: 100%;
        border: 1px solid #eaeaea;
        thead {

        }
        tbody {

        }
        tr {
          th {
            padding: 16px 12px;
            border: 1px solid #eaeaea;
            .gradeIcon {
              display: block;
              margin-bottom: 8px;
              > img {
                width: 48px; 
                height: 48px;
              }
            }
            span {
              display: inline-block;
              font-size: 18px;
              color: #000000;
              font-weight: 500;
              text-align: center;
              word-break: break-all;
            }
          }
          td {
            padding: 12px;
            border: 1px solid #eaeaea;
            span {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              font-size: 14px;
              font-weight: 400;
              text-align: center;
              line-height: 1.22;
              color: #666666;
              // word-break: break-word;
              word-break: keep-all;
              em {
                font-size: 12px;
                color: #666666;
              }
            }
            &:first-child {
              span {
                flex-direction: column;
                font-size: 15px;
                color: #000000;
                font-weight: 500;
                text-align: center;
              }
            }
          }
        }
        &.none {
          th[data-grade*="NONE"],
          td[data-grade*="NONE"] {
            background: $color-primary;
            span {
              color: #fff;
            }
          }
        }
        &.member {
          th[data-grade*="MEMBER"],
          td[data-grade*="MEMBER"] {
            background: rgba(255, 193, 8, 0.1);
            span {
              color: #000;
            }
          }
        }
        &.silver {
          th[data-grade*="SILVER"],
          td[data-grade*="SILVER"] {
            background: rgba(255, 193, 8, 0.1);
            span {
              color: #000;
            }
          }
        }
        &.gold {
          th[data-grade*="GOLD"],
          td[data-grade*="GOLD"] {
            background: rgba(255, 193, 8, 0.1);
            span {
              color: #000;
            }
          }
        }
        &.platinum {
          th[data-grade*="PLATINUM"],
          td[data-grade*="PLATINUM"] {
            background: rgba(255, 193, 8, 0.1);
            span {
              color: #000;
            }
          }
        }
        &.vvip {
          th[data-grade*="VVIP"],
          td[data-grade*="VVIP"] {
            background: rgba(255, 193, 8, 0.1);
            span {
              color: #000;
            }
          }
        }
      }
    }
    .gradeGuide {
      margin-top: 20px;
      .guideTit {
        span {
          font-size: 20px;
          line-height: 34px;
          font-weight: 700;
        }
      }
      .desc {
        margin-top: 8px;
        p {
          position: relative;
          padding-left: 8px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: left;
          color: $color-gray2;
          &::after {
            content: "-";
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}