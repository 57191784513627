@import "./../styles/variables.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeight;
  background-color: $color-white;
  border-bottom: 1px solid $color-gray6;
  transition: all 0.3s;
  z-index: 100;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $container;
    min-width: $minwidth;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;

    // gnb
    .gnb {
      display: flex;
      align-items: center;

      .logo {
        margin-right: 26px;
        img {
          width: 105px;
        }
      }
      .nav {
        ul {
          display: flex;

          li {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: left;
            color: $color-gray1;
            margin: 0 16px;
            &.active {
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              letter-spacing: normal;
              text-align: left;
              color: $color-primary;
            }
          }
        }
      }
    }
    // service
    .service {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;

      .searchForm {
        position: relative;
        width: 360px;
        padding: 0 12px;
        border-radius: 8px;
        border: 1px solid $color-gray6;
        background-color: #fff;

        .searchEle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          input {
            width: 100%;
            border: none;
            outline: none;
            font-size: 14px;
            color: #000;
            ::placeholder {
              color: red;
            }
          }
          button {
            flex-shrink: 0;
            cursor: pointer;
          }
        }
        .searchOptions {
          position: absolute;
          top: 40px;
          left: -1px;
          width: 360px;
          max-height: 292px;
          overflow-y: scroll;
          background-color: #fff;
          border: solid $color-gray6;
          border-width: 0 1px 1px 1px;
          border-radius: 0 0 8px 8px;
          z-index: 2;
          .optionHeader {
            padding: 8px 12px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: $color-gray2;
          }
          .optionList {
            .noDatas {
              padding: 8px 12px;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.67;
              letter-spacing: normal;
              text-align: left;
              color: $color-gray2;
            }
            .optionItem {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 32px 10px 12px;
              .itemTit {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: left;
                color: $color-black;
                cursor: pointer;
              }
              .btnRemove {
              }
            }
          }
        }
      }

      .util {
        display: flex;
        align-items: center;
        height: 100%;
        button {
          height: 100%;
          &.alert {
            margin-left: 40px;
          }
          &.mypage {
            margin-left: 24px;
          }
        }
      }

      .modalAlert {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #fff;
        width: 380px;
        border: 1px solid $color-gray6;
        .itemList {
          .item {
            display: flex;
            padding: 16px 20px;
            cursor: pointer;
            .noAlerts {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              padding: 4px 0;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              text-align: center;
              color: $color-gray3;
            }
            .img {
              flex-shrink: 0;
              width: 36px;
              height: 36px;
              margin-right: 12px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .desc {
              display: flex;
              flex-direction: column;
              .note {
                white-space: pre-line;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: $color-black;
                em {
                  font-weight: 500;
                }
              }
              .date {
                margin-top: 8px;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: left;
                color: $color-gray3;
              }
            }
            &:hover {
              background-color: rgba(255, 193, 8, 0.1);
            }
          }
        }
        .more {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 18px 0;
          cursor: pointer;
          img {
            margin-left: 6px;
          }
        }
      }
      .modalMypage {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #fff;
        width: 184px;
        border: 1px solid $color-gray6;

        a {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 56px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: $color-black;
          cursor: pointer;
        }
      }
    }
  }
}
