.button-form {
  max-width: 100%;
  .btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid;
    max-width: 100%;
  }
  .btn-md {
    width: 112px;
    height: 36px;
    padding: 0;
    border-radius: 6px;
    font-size: 12px;
  }
  .btn-lg {
    width: 156px;
    height: 48px;
    padding: 0;
    border-radius: 6px;
    font-size: 14px;
  }
  .btn-xl {
    width: 360px;
    height: 56px;
    padding: 0;
    border-radius: 8px;
    font-size: 16px;
  }
  .btn-cart {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border-color: $color-gray6;
  }
  .btn--primary {
    background-color: $color-primary;
    border-color: $color-primary;
  }
  .btn--outline_primary {
    background-color: $color-white;
    border-color: $color-primary;
  }
  .btn--outline_black {
    background-color: $color-white;
    border-color: $color-black;
  }
  .btn--outline_gray {
    background-color: $color-white;
    border-color: $color-gray5;
  }
  .btn--disabled {
    background-color: $color-gray6;
    border-color: $color-gray6;
    color: $color-gray4;
    cursor: default;
    pointer-events: none;
  }
  .btn-channel {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border-color: transparent;
    background: url("../../assets/icons/button-channel.svg") center center
      no-repeat;
  }
  .btn-sns {
    display: inline-block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-color: transparent;
  }
  .btn--kakao {
    background: url("../../assets/icons/button-kakao.svg") center center
      no-repeat;
  }
  .btn--facebook {
    background: url("../../assets/icons/button-facebook.svg") center center
      no-repeat;
  }
  .btn--naver {
    background: url("../../assets/icons/button-naver.svg") center center
      no-repeat;
  }
  .btn--link {
    background: url("../../assets/icons/button-link.svg") center center
      no-repeat;
  }
}
