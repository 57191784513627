@import "./../styles/variables.scss";

.modal {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    // pointer-events: none;

    &.dim {
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 100;
    }
    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      width: 414px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 56px;
        span {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: center;
          color: $color-black;
        }
      }
      .body {
        min-height: 768px;
        max-height: calc(100% - 128px);
        .itemList {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 20px 12px;
          padding: 20px 27px 0 20px;
          .itemBtn {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: calc((100% / 3) - 8px);
            height: 40px;
            background-color: $color-white;
            border: 1px solid $color-gray5;
            border-radius: 20px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: center;
            color: $color-gray2;
            transition: border-color 0.15s, background-color 0.15s, color 0.15s;
            &.active {
              border-color: $color-primary;
              background-color: #fef1cc;
              color: $color-black;
            }
          }
        }
      }
      .footer {
        padding: 0 27px 16px 27px;
      }
      .btn_close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 14px;
        height: 14px;
        background: url("../assets/icons/close.svg") center center no-repeat;
        border: none;
        cursor: pointer;
      }
    }
  }
}
