@import "./../../styles/variables.scss";

.notification {
  .header {
    display: flex;
    align-items: center;
    h1 {
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: normal;
      text-align: left;
      color: $color-black;
    }
    .dateRange {
      display: flex;
      align-items: center;
      margin-left: 28px;
      > div:first-child {
        position: relative;
        margin-right: 12px;
        &::before {
          position: absolute;
          top: 50%;
          right: -9px;
          transform: translateY(-50%);
          content: "~";
          font-size: 10px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: center;
          color: $color-black;
        }
      }
    }
  }
  .contents {
    margin-top: 40px;
    .itemWrapper {
      .contHeader {
        .item {
          display: flex;
          align-items: center;
          height: 58px;
          background-color: $color-gray7;
          border: solid $color-gray6;
          border-width: 1px 0 1px 0;
          .tdChk {
            flex-shrink: 0;
            margin-right: 6px;
            label {
              span {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: $color-black;
              }
            }
          }
          .tdContent {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-right: 1px solid $color-gray6;
          }
          .tdYear,
          .tdDate {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 288px;
            border-right: 1px solid $color-gray6;
          }
          .tdTit {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: normal;
            text-align: center;
            color: $color-black;
          }
        }
      }
      .itemCont {
        .itemList {
          // min-height: 200px;
          .item {
            position: relative;
            display: flex;
            align-items: center;
            height: 60px;
            border-bottom: 1px solid $color-gray6;
            cursor: pointer;
            .tdContent {
              position: relative;
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              width: calc(100% - 288px);
              padding-left: 26px;
              .img {
                width: 36px;
                height: 36px;
                margin-right: 20px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            .tdYear,
            .tdDate {
              position: relative;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              width: 288px;

              button:not(:last-child) {
                margin-bottom: 16px;
              }
              //   &:not(:last-child)::after {
              //     position: absolute;
              //     top: 50%;
              //     right: 0;
              //     transform: translateY(-50%);
              //     content: "";
              //     width: 1px;
              //     height: 100px;
              //     background-color: $color-gray6;
              //   }
            }
            .mainTxt {
              display: inline-block;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.78;
              letter-spacing: normal;
              text-align: left;
              color: $color-black;
              em {
                font-weight: 500;
              }
            }
            .descTxt {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.75;
              letter-spacing: normal;
              text-align: center;
              color: $color-black;
            }
            &.open {
              align-items: flex-start;
              padding: 12px 0;
              height: auto;
              .tdContent {
                align-items: flex-start;
              }
              .mainTxt {
                white-space: pre-line;
              }
            }
          }
        }
      }
      // 아이템 empty
      .nonItems {
        margin-top: 120px;
        text-align: center;
        .icon {
          img {
            width: 120px;
            height: 120px;
          }
        }
        p {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: center;
          color: $color-gray1;
          margin-top: 24px;
        }
      }
    }
  }
}

.pagination {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    .numList {
      position: relative;
      display: flex;
      align-items: center;
      .btnPrev {
        position: absolute;
        top: 50%;
        left: -42px;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 16px;
        &[aria-current="false"]{
          opacity: 0.2;
          pointer-events: none;
        }
      }
      .btnNext {
        position: absolute;
        top: 50%;
        right: -42px;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 16px;
        &[aria-current="false"]{
          opacity: 0.2;
          pointer-events: none;
        }
      }
      .currentPage {
        display: flex;
        align-items: center;
        gap: 0 4px;
        li {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: $color-gray1;
          cursor: pointer;
          &.active {
            color: #000;
            background-color: $color-primary;
          }
        }
      }
      .lastPage {
        position: relative;
        margin-left: 15px;
        padding-left: 30px;
        cursor: pointer;
        &::before {
          content: "...";
          position: absolute;
          bottom: 0;
          left: 0;
          cursor: default;
        }
      }
    }
  }