@import "./../styles/variables.scss";

.join {
  padding: 60px 0 120px 0;

  &__header {
    position: relative;
  }

  .tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    .tab {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border: 1px solid $color-gray5;
      border-radius: 4px;
      background-color: #fff;
      cursor: pointer;
      pointer-events: none;
      span {
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $color-gray5;
      }
      &.active {
        pointer-events: unset;
        border: 1px solid $color-primary;
        background: $color-primary;
        span {
          color: $color-white;
        }
      }
    }
  }

  &__content {
    display: none;
    margin-top: 12px;

    &__visible {
      display: block;
    }

    &__mainTxt {
      display: block;
    }
    &__subTxt {
      margin-top: 8px;
    }
    &__termsBox {
      margin-top: 60px;

      &__all {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-gray6;
      }
      &__normal {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__formContainer {
      margin-top: 36px;
    }
  }
  &__buttons {
    margin-top: 60px;
  }
}

.isVisible {
  display: block;
}
