@import "./../styles/variables.scss";

.modal {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    // pointer-events: none;

    &.dim {
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 100;
    }
    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      width: 414px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 56px;
        span {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: center;
          color: $color-black;
        }
      }
      .body {
        min-height: 768px;
        max-height: calc(100% - 128px);
        // .itemList {
        //   display: flex;
        //   justify-content: space-between;
        //   align-items: center;
        //   flex-wrap: wrap;
        //   gap: 20px 12px;
        //   padding: 20px 27px 0 20px;
        //   .itemBtn {
        //     display: inline-flex;
        //     justify-content: center;
        //     align-items: center;
        //     width: calc((100% / 3) - 8px);
        //     height: 40px;
        //     background-color: $color-white;
        //     border: 1px solid $color-gray5;
        //     border-radius: 20px;
        //     font-size: 14px;
        //     font-weight: 500;
        //     font-stretch: normal;
        //     font-style: normal;
        //     line-height: 1.71;
        //     letter-spacing: normal;
        //     text-align: center;
        //     color: $color-gray2;
        //     transition: border-color 0.15s, background-color 0.15s, color 0.15s;
        //     &.active {
        //       border-color: $color-primary;
        //       background-color: #fef1cc;
        //       color: $color-black;
        //     }
        //   }
        // }
        .frmCont {
          margin-top: 24px;
          padding: 0 27px;
          .itemCont {
            margin-bottom: 24px;
            .itemHeader {
              display: flex;
              // justify-content: space-between;
              align-items: center;
              gap: 8px;
              .tit {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: left;
                color: $color-gray2;
              }
              &.required {
                span {
                  position: relative;
                }
                span::after {
                  content: "*";
                  position: absolute;
                  top: -3px;
                  left: 100%;
                  margin-left: 4px;
                  font-size: 18px;
                  font-weight: 700;
                  color: #ea5a00;
                }
              }
              .chkItem {
                // width: calc(100% - 54px);
                div {
                  width: 20px;
                  height: 20px;
                  background-size: 20px;
                  margin-right: 4px;
                }
                span {
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.67;
                  letter-spacing: normal;
                  text-align: left;
                  color: $color-gray2;
                }
              }
            }
            .itemBody {
              margin-top: 12px;
              .warningTxt{
                font-size: 12px;
                color: red;
                margin-top: 4px;
              }
              .searchForm {
                position: relative;
                width: 360px;
                padding: 0 12px;
                border-radius: 8px;
                border: 1px solid $color-gray6;
                background-color: #fff;
        
                .searchEle {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 10px 0;
                  input {
                    width: 100%;
                    border: none;
                    outline: none;
                    font-size: 14px;
                    color: #000;
                    ::placeholder {
                      color: red;
                    }
                  }
                  button {
                    flex-shrink: 0;
                    cursor: pointer;
                  }
                }
                .searchOptions {
                  position: absolute;
                  top: 40px;
                  left: -1px;
                  width: 360px;
                  max-height: 292px;
                  overflow-y: scroll;
                  background-color: #fff;
                  border: solid $color-gray6;
                  border-width: 0 1px 1px 1px;
                  border-radius: 0 0 8px 8px;
                  z-index: 2;
                  .optionHeader {
                    padding: 8px 12px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.67;
                    letter-spacing: normal;
                    text-align: left;
                    color: $color-gray2;
                  }
                  .optionList {
                    .noDatas {
                      padding: 8px 12px;
                      font-size: 12px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.67;
                      letter-spacing: normal;
                      text-align: left;
                      color: $color-gray2;
                    }
                    .optionItem {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding: 10px 32px 10px 12px;
                      .itemTit {
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.67;
                        letter-spacing: normal;
                        text-align: left;
                        color: $color-black;
                        cursor: pointer;
                      }
                      .btnRemove {
                      }
                    }
                  }
                }
              }
              .rangeItem {
                display: flex; 
                justify-content: space-between;
                align-items: center;
                .rangeTxt {
                  font-size: 16px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.75;
                  letter-spacing: normal;
                  text-align: left;
                  color: $color-black;
                }

                &.disabled {
                  pointer-events: none;
                  // background: $color-gray2;
                }
              }
              .linkedModal {
                display: flex;
                flex-direction: column;
                &:not(:last-child) {
                  margin-bottom: 20px;
                }
                .btnItem {
                  display: inline-flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 360px;
                  height: 48px;
                  padding: 0 12px;
                  border: 1px solid $color-gray6;
                  border-radius: 8px;
                  span {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    text-align: left;
                    color: $color-gray5;
                    &.active {
                      color: $color-black;
                    }
                  }
                  img {
                  }
                }
              }
            }
          }
        }
      }
      .footer {
        padding: 0 27px 16px 27px;
      }
      .btn_close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 14px;
        height: 14px;
        background: url("../assets/icons/close.svg") center center no-repeat;
        border: none;
        cursor: pointer;
      }
    }
  }
}
