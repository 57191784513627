.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;

    &.dim {
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 100;
    }
}

.share {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 360px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
    padding: 30px 0;

    .btnClose {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 24px;
        height: 24px;

        >img {
            width: 100%;
            height: 100%;
        }
    }

    .tit {
        padding: 0 24px;
        margin-top: 12px;

        >span {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            color: #000000;
        }
    }

    .itemList {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding: 0 40px;
        margin-top: 30px;

        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            .img {
                >img {}
            }

            .txt {
                >span {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    color: #000000;
                }
            }
        }
    }

    .urlShare {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        padding: 0 24px;
        height: 40px;
        gap: 12px;

        .url {
            width: 100%;
            height: 100%;

            >input {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                border: 1px solid #bfbfbf;
                outline: none;
                font-size: 14px;
                font-weight: 400;
                color: #000000;
                padding: 0 8px;
            }
        }

        >button {
            position: relative;
            flex-shrink: 0;
            width: 80px;
            height: 100%;
            border: 1px solid #bfbfbf;
            border-radius: 8px;
        }
    }
}