@import "./../styles/variables.scss";

.service {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 52px 0 120px 0;

  // 부가서비스 신청
  .application {
    width: 100%;
    max-width: 552px;
    margin-right: 120px;
    .header {
      h1 {
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: left;
        color: $color-black;
      }
      p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: $color-gray1;
        margin-top: 16px;
      }
    }
    .contents {
      margin-top: 28px;
      .itemWrapper {
        .itemCont {
          padding: 32px 0;
          border-bottom: 1px solid $color-gray6;
          &:last-child {
            padding-bottom: 0;
            border-bottom: none;
          }
          .itemHeader {
            margin-bottom: 20px;
            .itemTit {
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.7;
              letter-spacing: normal;
              text-align: left;
              color: $color-black;
            }
          }
          .chkList {
            display: flex;
            flex-wrap: wrap;
            .chkItem {
              margin: 0 24px 17px 0;
              span {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
                text-align: left;
                color: $color-gray3;
              }
              input:checked ~ span {
                color: $color-black;
                em {
                  font-weight: bold;
                }
              }
            }
          }
          .addressWrapper {
            margin-top: 28px;
            .addHeader {
              display: flex;

              .btnRadio {
                width: 180px;
                padding: 10px 0;
                border: 1px solid $color-gray5;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: center;
                color: $color-gray2;
                transition: border-color 0.35s, color 0.35s;
                &.active {
                  border-color: $color-primary;
                  color: $color-black;
                }
                &:not(:first-child) {
                  margin-left: -1px;
                }
              }
            }
            .addCont {
              margin-top: 24px;
              .originAdd {
                .radioItem {
                  margin-bottom: 12px;
                }
              }
              .newAdd {
                width: 360px;
                .chkItem {
                  margin: 16px 0;
                  span {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    text-align: left;
                    color: $color-gray1;
                  }
                }
              }
            }
          }
          .itemNote {
            background-color: rgba(44, 71, 98, 0.03);
            padding: 16px;
            margin-top: 15px;
            .noteTit {
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: $color-black;
            }
            .noteDesc {
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.67;
              letter-spacing: normal;
              text-align: left;
              color: $color-gray3;
              margin-top: 12px;
              p {
                @extend .noteDesc;
                position: relative;
                padding-left: 8px;
                margin-top: 0;
                &::before {
                  content: "-";
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
          }
          .subTxt {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: left;
            color: #3d3d3d;
            em {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  // 부가서비스 선택 정보
  .summary {
    position: sticky;
    top: 140px;
    width: 100%;
    max-width: 456px;
    border: 1px solid $color-gray6;
    .header {
      padding: 20px;
      border-bottom: 1px solid $color-gray6;
      h3 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: normal;
        text-align: left;
        color: $color-black;
      }
    }
    .contents {
      padding: 20px 20px 0 20px;
      .itemList {
        padding-bottom: 8px;
        .item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          .itemTit {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
            em {
              font-weight: bold;
            }
          }
          .itemDesc {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: normal;
            text-align: right;
            color: $color-black;
          }
        }
      }
      .total {
        padding: 20px 0;
        border: solid $color-gray6;
        border-width: 1px 0 1px 0;
        .item {
          display: flex;
          justify-content: space-between;
          .itemTit {
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
          }
          .itemDesc {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: right;
            color: $color-black;
          }
        }
      }
    }
    .buttons {
      padding: 24px 20px 28px 20px;

      button {
        width: 100%;
      }
    }
  }
}
