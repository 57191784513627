/****************************** Design System ******************************/

/* Color palette */
// Main-Color
$color-primary: #ffc108;
$color-secondary: #2c4762;
$color-third: #ff7a3b;
// System-Color
$color-susscess: #6cd15a;
$color-danger: #e64841;
// Grayscales
$color-black: #000000;
$color-gray1: #3d3d3d;
$color-gray2: #666666;
$color-gray3: #808080;
$color-gray4: #9f9f9f;
$color-gray5: #bfbfbf;
$color-gray6: #eaeaea;
$color-gray7: #f6f6f6;
$color-white: #ffffff;

/* Layout */
$container: 1128px;
$minwidth: 1040px;
$formWidth: 360px;
$headerHeight: 80px;
$spacing-md: 16px;
$spacing-lg: 32px;

@mixin breakpoint($point) {
  @if $point == md {
    // 768px
    @media (min-width: 48em) {
      @content;
    }
  }
}
