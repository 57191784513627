@import "./../styles/variables.scss";

.quickSNS {
  position: fixed;
  bottom: 80px;
  right: 16px;
  cursor: pointer;
  img {
  }
}
