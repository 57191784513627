@import "./../styles/variables.scss";

.postDetail {
  padding-top: 52px;
  .header {
    h1 {
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: normal;
      text-align: left;
      color: $color-black;
    }
    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: $color-gray1;
      margin-top: 16px;
    }
  }
  .contents {
    margin-top: 40px;
    padding-bottom: 120px;
    .postHeader {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 24px;
      border: solid $color-gray6;
      border-width: 1px 0 1px 0;
      .title {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        color: $color-black;
      }
      .date {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        color: $color-black;
      }
    }
    .postContent {
      margin-top: 30px;
      .textarea {
        width: 100%;
        min-height: 400px;
        border: none;
        resize: none;
        outline: none;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: $color-black;
      }
    }
  }
  .buttons {
    margin-top: 60px;
    text-align: center;
  }
}
