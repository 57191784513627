// 애니메이션 구현
.wow-enter {
  // 애니메이션 시작 CSS
  opacity: 0;
}
.wow-enter-active {
  // 애니메이션 동작 CSS
  opacity: 1;
  transition: all 0.5s;
}
.wow-exit {
  // 애니메이션 종료 CSS
}
