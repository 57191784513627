@import "./../../styles/variables.scss";

.dashboard {
  padding: 52px 0 0 0;
}

.managerPhone {
  padding: 60px 0 120px 0;

  .header {
    position: relative;
  }
  .content {
    .mainTxt {
      display: block;
    }
    .termsBox {
      margin-top: 60px;

      .all {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-gray6;
      }
      .normal {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .formContainer {
      margin-top: 40px;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 60px;
  }
}
