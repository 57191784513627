@import "./../styles/variables.scss";

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 0 0;

  &__slogan {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: $color-black;
    margin-top: 102px;

    em {
      font-weight: bold;
      color: $color-primary;
    }
  }
  &__logo {
    margin-top: 10px;

    img {
      width: 307px;
    }
  }
  &__form {
    width: $formWidth;
    margin-top: 60px;
  }
  &__mnb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    li {
      position: relative;
      margin-right: 16px;
      padding-right: 16px;
      &:last-child {
        margin-right: 0;
        padding-right: 0;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 12px;
        background-color: $color-gray5;
      }
      &:last-child::after {
        width: 0;
        height: 0;
        background-color: none;
      }

      a {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: $color-black;
      }
    }
  }
}
