@import "./../styles/variables.scss";

.bottomCard {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 100;

  .cardHeader {
    background-color: $color-gray1;

    .inner {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 36px;
      button {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.29;
        letter-spacing: normal;
        text-align: left;
        color: $color-white;
        margin-left: 28px;
        img {
          margin-left: 4px;
        }
      }
    }
  }
  .cardBody {
    background-color: $color-gray7;
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100px;
      .contents {
        display: flex;
        align-items: center;
        .img {
          margin-right: 20px;
          img {
          }
        }
        .desc {
          .mainTxt {
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.7;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
          }
          .subTxt {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
            margin-top: 4px;
          }
        }
      }
      .btnAlert {
        max-width: 264px;
      }
    }
  }
}
