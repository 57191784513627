@import "./../styles/variables.scss";

.contact {
  .contents {
    padding-top: 52px;
    .tabWrapper {
      .tabHeader {
        display: flex;

        .btnRadio {
          width: 180px;
          padding: 10px 0;
          border: 1px solid $color-gray5;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: $color-gray2;
          transition: border-color 0.35s, color 0.35s;
          &.active {
            border-color: $color-primary;
            color: $color-black;
          }
          &:not(:first-child) {
            margin-left: -1px;
          }
        }
      }
      .tabContainer {
        margin-top: 52px;
        .tab {
          .tabHeader {
            .tabTit {
              font-size: 28px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.64;
              letter-spacing: normal;
              text-align: left;
              color: $color-black;
            }
          }
          .tabInner {
            margin-top: 36px;
          }
          // 탭1 공지사항
          &.tabNotice {
            .itemWrapper {
              .contHeader {
                .item {
                  display: flex;
                  align-items: center;
                  height: 58px;
                  background-color: $color-gray7;
                  border: solid $color-gray6;
                  border-width: 1px 0 1px 0;
                  .tdChk {
                    flex-shrink: 0;
                    margin-right: 6px;
                    label {
                      span {
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.71;
                        letter-spacing: normal;
                        text-align: left;
                        color: $color-black;
                      }
                    }
                  }
                  .tdContent {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    border-right: 1px solid $color-gray6;
                  }
                  .tdYear,
                  .tdDate {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    width: 288px;
                    border-right: 1px solid $color-gray6;
                  }
                  .tdTit {
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.78;
                    letter-spacing: normal;
                    text-align: center;
                    color: $color-black;
                  }
                }
              }
              .itemCont {
                .itemList {
                  .item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 72px;
                    border-bottom: 1px solid $color-gray6;
                    .tdContent {
                      position: relative;
                      display: inline-flex;
                      justify-content: flex-start;
                      align-items: center;
                      width: calc(100% - 288px);
                      padding-left: 26px;
                    }
                    .tdYear,
                    .tdDate {
                      position: relative;
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                      flex-shrink: 0;
                      width: 288px;

                      button:not(:last-child) {
                        margin-bottom: 16px;
                      }
                      //   &:not(:last-child)::after {
                      //     position: absolute;
                      //     top: 50%;
                      //     right: 0;
                      //     transform: translateY(-50%);
                      //     content: "";
                      //     width: 1px;
                      //     height: 100px;
                      //     background-color: $color-gray6;
                      //   }
                    }
                    .mainTxt {
                      display: inline-block;
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      font-size: 18px;
                      font-weight: 500;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.78;
                      letter-spacing: normal;
                      text-align: left;
                      color: $color-black;
                    }
                    .descTxt {
                      font-size: 18px;
                      font-weight: 500;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.78;
                      letter-spacing: normal;
                      text-align: left;
                      color: $color-black;
                    }
                  }
                }
              }
              // 아이템 empty
              .nonItems {
                margin-top: 120px;
                text-align: center;
                .icon {
                  img {
                    width: 120px;
                    height: 120px;
                  }
                }
                p {
                  font-size: 18px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.78;
                  letter-spacing: normal;
                  text-align: center;
                  color: $color-gray1;
                  margin-top: 24px;
                }
              }
            }
          }
          &.tabQna {
            .itemWrapper {
              padding-bottom: 80px;
              .accordian {
                border-top: 1px solid $color-gray6;
                details {
                  padding: 24px;
                  border-bottom: 1px solid $color-gray6;
                  summary {
                    position: relative;
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.78;
                    letter-spacing: normal;
                    text-align: left;
                    color: $color-black;
                    list-style: none;
                    padding: 0 40px 0 25px;
                    cursor: pointer;
                    &::before {
                      position: absolute;
                      top: 0;
                      left: 0;
                      content: "Q.";
                      font-size: 18px;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.78;
                      letter-spacing: normal;
                      text-align: left;
                      color: $color-black;
                    }
                    &::after {
                      content: "";
                      position: absolute;
                      top: 4px;
                      right: 0;
                      transform: rotate(0);
                      background: url("../assets/icons/under.svg") center center
                        no-repeat;
                      background-size: cover;
                      width: 24px;
                      height: 24px;
                      transition: transform 0.35s;
                    }
                    &::-webkit-details-marker {
                      display: none;
                    }
                  }
                  &[open] summary::after {
                    transform: rotate(180deg);
                  }
                  .anwser {
                    position: relative;
                    margin-top: 40px;
                    padding-bottom: 13px;
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.78;
                    letter-spacing: normal;
                    text-align: left;
                    color: $color-black;
                    padding: 0 40px 0 25px;
                    white-space: pre-wrap;
                    &::before {
                      position: absolute;
                      top: 0;
                      left: 0;
                      content: "A.";
                      font-size: 18px;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.78;
                      letter-spacing: normal;
                      text-align: left;
                      color: $color-primary;
                    }
                    .link {
                      color: $color-primary;
                      font-weight: bold;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }

          .pagination {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 60px;
            .numList {
              position: relative;
              display: flex;
              align-items: center;
              .btnPrev {
                position: absolute;
                top: 50%;
                left: -42px;
                transform: translateY(-50%);
                cursor: pointer;
                font-size: 16px;
                &[aria-current="false"]{
                  opacity: 0.2;
                  pointer-events: none;
                }
              }
              .btnNext {
                position: absolute;
                top: 50%;
                right: -42px;
                transform: translateY(-50%);
                cursor: pointer;
                font-size: 16px;
                &[aria-current="false"]{
                  opacity: 0.2;
                  pointer-events: none;
                }
              }
              .currentPage {
                display: flex;
                align-items: center;
                gap: 0 4px;
                li {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  font-size: 14px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.71;
                  letter-spacing: normal;
                  text-align: center;
                  color: $color-gray1;
                  cursor: pointer;
                  &.active {
                    color: #000;
                    background-color: $color-primary;
                  }
                }
              }
              .lastPage {
                position: relative;
                margin-left: 15px;
                padding-left: 30px;
                cursor: pointer;
                &::before {
                  content: "...";
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }
  }
}
