@import "./../../styles/variables.scss";

.mileage {
  .infoSummary {
    display: flex;
    padding: 23px 28px;
    background-color: rgba(255, 193, 8, 0.1);
    .possession {
      width: 50%;
      flex-shrink: 0;
      .tit {
        span {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: left;
          color: $color-black;
        }
      }
      .desc {
        display: flex;
        align-items: center;
        margin-top: 8px;
        span {
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.64;
          letter-spacing: normal;
          text-align: left;
          color: $color-black;
        }
        button {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          border: 1px solid $color-black;
          border-radius: 13px;
          padding: 2px 8px;
          margin-left: 12px;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: left;
          color: $color-black;
        }
      }
    }
    .sumDesc {
      position: relative;
      width: 50%;
      padding-left: 40px;
      border-left: 1px solid $color-gray6;
      p {
        position: relative;
        padding-left: 8px;
        margin-top: 0;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: left;
        color: $color-gray3;
        &::after {
          content: "-";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    margin-top: 40px;
    h1 {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.7;
      letter-spacing: normal;
      text-align: left;
      color: $color-black;
    }
    .dateRange {
      display: flex;
      align-items: center;
      margin-left: 28px;
      > div:first-child {
        position: relative;
        margin-right: 12px;
        &::before {
          position: absolute;
          top: 50%;
          right: -9px;
          transform: translateY(-50%);
          content: "~";
          font-size: 10px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: center;
          color: $color-black;
        }
      }
    }
  }
  .contents {
    margin-top: 40px;
    .itemWrapper {
      .contHeader {
        .item {
          display: flex;
          align-items: center;
          height: 58px;
          padding: 0 16px;
          background-color: $color-gray7;
          border: solid $color-gray6;
          border-width: 1px 0 1px 0;
          .tdChk {
            flex-shrink: 0;
            margin-right: 6px;
            label {
              span {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: $color-black;
              }
            }
          }
          .tdContent {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-right: 1px solid $color-gray6;
          }
          .tdYear,
          .tdAmount,
          .tdNumber,
          .tdDate {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 192px;
            border-right: 1px solid $color-gray6;
          }
          .tdTit {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: normal;
            text-align: center;
            color: $color-black;
          }
        }
      }
      .itemCont {
        .itemList {
          .item {
            position: relative;
            display: flex;
            align-items: center;
            height: 60px;
            padding: 36px 16px;
            border-bottom: 1px solid $color-gray6;
            .tdContent {
              position: relative;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            }
            .tdYear,
            .tdAmount,
            .tdNumber,
            .tdDate {
              position: relative;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              width: 192px;

              button:not(:last-child) {
                margin-bottom: 16px;
              }
              //   &:not(:last-child)::after {
              //     position: absolute;
              //     top: 50%;
              //     right: 0;
              //     transform: translateY(-50%);
              //     content: "";
              //     width: 1px;
              //     height: 100px;
              //     background-color: $color-gray6;
              //   }
            }
            .mainTxt {
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.78;
              letter-spacing: normal;
              text-align: left;
              color: $color-black;
            }
            .descTxt {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.75;
              letter-spacing: normal;
              text-align: center;
              color: $color-black;
            }
            &.plus {
              .tdAmount {
                .descTxt {
                  font-weight: bold;
                  color: $color-secondary;
                }
              }
            }
            &.minus {
              .tdAmount {
                .descTxt {
                  font-weight: bold;
                  color: $color-gray4;
                }
              }
            }
          }
        }
      }
      // 아이템 empty
      .nonItems {
        margin-top: 120px;
        text-align: center;
        .icon {
          img {
            width: 120px;
            height: 120px;
          }
        }
        p {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: center;
          color: $color-gray1;
          margin-top: 24px;
        }
      }
    }
  }
}

.pagination {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  .numList {
    position: relative;
    display: flex;
    align-items: center;
    .btnPrev {
      position: absolute;
      top: 50%;
      left: -42px;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 16px;
      &[aria-current="false"]{
        opacity: 0.2;
        pointer-events: none;
      }
    }
    .btnNext {
      position: absolute;
      top: 50%;
      right: -42px;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 16px;
      &[aria-current="false"]{
        opacity: 0.2;
        pointer-events: none;
      }
    }
    .currentPage {
      display: flex;
      align-items: center;
      gap: 0 4px;
      li {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: $color-gray1;
        cursor: pointer;
        &.active {
          color: #000;
          background-color: $color-primary;
        }
      }
    }
    .lastPage {
      position: relative;
      margin-left: 15px;
      padding-left: 30px;
      cursor: pointer;
      &::before {
        content: "...";
        position: absolute;
        bottom: 0;
        left: 0;
        cursor: default;
      }
    }
  }
}