@import "./../styles/variables.scss";

.toast {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(61, 61, 61, 0.9);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  min-width: 360px;
  padding: 36px 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: opacity 0.35s;

  &.active {
    position: fixed;
    opacity: 1;
    visibility: visible;
    z-index: 200;
  }

  span {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: $color-white;
  }
}