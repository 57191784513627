@import "./../styles/variables.scss";

.modal {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    // pointer-events: none;

    &.dim {
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 100;
    }
    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      width: 414px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 56px;
        span {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: center;
          color: $color-black;
        }
      }
      .body {
        min-height: 768px;
        max-height: calc(100% - 128px);
        .frmCont {
          margin-top: 20px;
          .itemCont {
            padding: 0 27px 24px 27px;
            border-bottom: 8px solid $color-gray7;
            .itemHeader {
              span {
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.78;
                letter-spacing: normal;
                text-align: left;
                color: $color-black;
              }
              p {
                margin-top: 2px;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: #666666;
              }
            }
            .itemBody {
              margin-top: 30px;
              .radioList {
                display: flex;
                flex-direction: column;
                gap: 20px 0;
                padding-bottom: 25px;
                border-bottom: 1px solid $color-gray7;
                .radioItem {
                  label {
                    padding-left: 34px;
                    font-size: 16px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.75;
                    letter-spacing: normal;
                    text-align: left;
                    color: $color-black;
                  }
                }
              }
              .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 20px;
                .itemTit {
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: left;
                  color: $color-black;
                }
                .itemDesc {
                  font-size: 16px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: right;
                  color: $color-black;
                }
              }
            }
          }
          .itemRes {
            padding: 24px 27px;
            border-bottom: 8px solid $color-gray7;
            .item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .itemTit {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
                text-align: left;
                color: $color-black;
              }
              .itemDesc {
              }
              font-size: 18px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.78;
              letter-spacing: normal;
              text-align: right;
              color: $color-black;
            }
          }
          .itemAgree {
            padding: 27px;
            .desc {
              margin-top: 18px;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              text-align: left;
              color: $color-gray2;
              p {
                @extend .desc;
                position: relative;
                padding-left: 8px;
                margin-top: 0;
                &::after {
                  content: "-";
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
          }
        }
        .setCharge {
          padding-top: 60px;
          .guide {
            padding-bottom: 40px;
            border-bottom: 4px solid $color-gray7;
            .icon {
              text-align: center;
              img {
                width: 80px;
                height: 80px;
              }
            }
            .mainTxt {
              margin-top: 24px;
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.7;
              letter-spacing: normal;
              text-align: center;
              color: $color-black;
            }
            .subTxt {
              margin-top: 12px;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              text-align: center;
              color: $color-black;
            }
          }
          .details {
            padding: 20px 27px;
            .header {
              span {
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.78;
                letter-spacing: normal;
                text-align: left;
                color: $color-black;
              }
            }
            .itemList {
              margin-top: 4px;
              .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 12px 0;
                border-bottom: 1px solid $color-gray7;
                .itemTit {
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.71;
                  letter-spacing: normal;
                  text-align: left;
                  color: $color-black;
                }
                .itemDesc {
                  font-size: 14px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.71;
                  letter-spacing: normal;
                  text-align: right;
                  color: $color-black;
                }
              }
            }
            .total {
              margin-top: 12px;
              .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .itemTit {
                  font-size: 16px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.75;
                  letter-spacing: normal;
                  text-align: left;
                  color: $color-black;
                }
                .itemDesc {
                  font-size: 18px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.78;
                  letter-spacing: normal;
                  text-align: right;
                  color: $color-black;
                }
              }
            }
          }
          .info {
            padding: 20px 27px 0 27px;
            border-top: 4px solid $color-gray7;
            .infoTit {
              > span {
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.78;
                letter-spacing: normal;
                text-align: center;
                color: $color-black;
              }
            }
            .item {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              gap: 8px;
              margin-top: 12px;
              > span {
                font-size: 16px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: right;
                color: $color-black;
              }
            }
          }
        }
      }
    }
    .footer {
      padding: 0 27px 16px 27px;
    }
    .btn_close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 14px;
      height: 14px;
      background: url("../assets/icons/close.svg") center center no-repeat;
      border: none;
      cursor: pointer;
    }
  }
}
