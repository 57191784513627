@import "./../styles/variables.scss";

.quickMenu {
  position: fixed;
  top: $headerHeight;
  right: -81px;
  transition: right 0.35s;

  .btnQuickToggle {
    position: absolute;
    top: 50%;
    left: -24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 60px;
    background-color: #fff;
    border: solid $color-gray6;
    border-width: 1px 0 1px 1px;
    cursor: pointer;
    img {
      transform: rotate(180deg);
    }
  }

  .menuList {
    display: flex;
    flex-direction: column;
    border: solid $color-gray6;
    border-width: 0 0 1px 1px;
    .menuItem {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      background-color: #fff;
      a {
        cursor: pointer;
      }
      a, div {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        text-align: center;
        img {
          width: 32px;
          height: 32px;
        }
        span {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: $color-gray2;
        }
      }
      &:last-child {
        background-color: $color-primary;
        span {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: center;
          color: $color-black;
          em {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: center;
            color: $color-black;
            margin-top: 4px;
          }
        }
      }
    }
  }

  &.active {
    right: 0;
    img {
      transform: rotate(0deg);
    }
  }
}
