.navStyles {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1128px;
    margin: 0 auto;
    padding-top: 120px;
}

.liStyles {
    display: block;
    margin-bottom: 18px;
}

.linkStyles {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.title {
    position: relative;
}

.note1, .note2, .note3, .note4 {margin-left: 12px;}
.note1 {
    color: #ffc108;
}
.note2 {
    color: rgb(8, 118, 255);
}
.note3 {
    color: rgb(255, 65, 8);
}
.note4 {
    color: #ab08ff;
}