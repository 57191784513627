@import "./../../styles/variables.scss";

.profile {
  .checkingPw {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    .desc {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: $color-black;
    }
    .frmCont {
      margin-top: 40px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    h1 {
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: normal;
      text-align: left;
      color: $color-black;
    }
  }
  .contents {
    .boxList {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-top: 40px;
      .box {
        width: calc((100% / 3) - 16px);
        min-width: 360px;
        min-height: 350px;
        background-color: $color-white;
        border: 1px solid $color-gray6;
        padding: 20px;
        .boxHeader {
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .itemTit {
              font-size: 18px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.78;
              letter-spacing: normal;
              text-align: left;
              color: $color-black;
            }
            .btnDepth {
              display: inline-flex;
              align-items: center;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.67;
              letter-spacing: normal;
              text-align: right;
              color: $color-gray3;
              img {
                margin-left: 4px;
              }
            }
          }
        }
        .boxContent {
          margin-top: 16px;
          .itemList {
            .item {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              &:not(:last-child) {
                margin-bottom: 12px;
              }
              .itemTit {
                width: 96px;
                flex-shrink: 0;
                margin-right: 20px;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: $color-gray3;
              }
              .itemDesc {
                width: calc(100% - 90px);
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: right;
                color: $color-black;
                .btnFileDownload {
                  text-decoration: underline;
                  word-break: break-all;
                }
              }
            }
          }
        }
      }
    }
  }
}
