@import "./../styles/variables.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;

  &.dim {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
  }
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 360px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;

  .title {
    padding: 32px 0 8px 0;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: $color-black;
    padding-bottom: 8px;
  }
  .body {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: $color-gray1;
  }
  .footer {
    display: flex;
    margin-top: 36px;
    border-top: 1px solid $color-gray6;

    button {
      flex: 1;
      border: none;
      background-color: #fff;
      padding: 16px 0;
      margin: 0;
      border-right: 1px solid $color-gray6;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: $color-gray5;
      cursor: pointer;

      &.btnOk {
        color: $color-primary;
      }
    }
    button:last-child {
      border-right: none;
    }
  }
}
