@import "./../styles/variables.scss";

.react-datepicker {
  font-family: inherit;
  font-size: 14px;
  padding: 24px 24px 54px 24px;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: none;
}
// header
.react-datepicker__header {
  padding-top: 0;
  background-color: transparent;
  border-bottom: 1px solid $color-gray6;
  text-align: left;
}
.react-datepicker__month {
  margin-top: 12px;
}
// header arrow
.react-datepicker__navigation--previous {
  top: 20px;
  left: 24px;
}
.react-datepicker__navigation--next {
  top: 20px;
  left: 114px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  width: 8px;
  height: 8px;
  border-width: 2px 2px 0 0;
}
// nav
.react-datepicker__current-month {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-left: 36px;
}
// cells
.react-datepicker__day-name,
.react-datepicker__day {
  width: 36px;
  height: 36px;
  margin: 0 4px;
  transition: 0.1s;
}
// weeks
.react-datepicker__day-names {
  margin-top: 24px;

  .react-datepicker__day-name {
    font-size: 14px;
    font-weight: 500;
    color: $color-gray4 !important;
  }
}
// days
.react-datepicker__day-names,
.react-datepicker__week {
  .react-datepicker__day-name,
  .react-datepicker__day {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 2.3;
  }
}
// today
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50%;
  background-color: $color-primary;
  color: #fff !important;
}
// hover
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border-radius: 50%;
  background-color: $color-primary;
  color: #fff !important;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
  background-color: $color-gray6;
  color: #000 !important;
}
// disabled date
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: $color-gray4 !important;
  text-decoration: line-through;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
  color: $color-gray4 !important;
  pointer-events: none;
}
// disabled other monthes highlight effect
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}
.react-datepicker__day--outside-month {
  color: $color-gray6 !important;
  text-decoration: unset;
}
// inputs
.react-datepicker__input-container {
  position: relative;
  input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 174px;
    height: 40px;
    padding: 0 8px 0 20px;
    border: 1px solid $color-gray5;
    border-radius: 20px;
    background-color: $color-white;
    // width: calc(100% - 48px);
    // border: none;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: $color-black;
    &:focus {
      outline: none;
    }
  }
  &::after {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    content: "";
    width: 24px;
    height: 24px;
    background: url("../assets/icons/calander.svg") center center no-repeat;
    background-size: cover;
    cursor: pointer;
  }
}

// custom
.cd-header {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 47px;

  .ch-tit {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: $color-black;
  }

  .cd-nav {
    margin: 0 12px;
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }
  .cd-arrow {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;

    .icon {
      display: flex;
      align-items: center;
      font-size: 22px;

      polyline {
        stroke: $color-gray3;
      }
    }
  }
}
