@import "./../styles/variables.scss";

.modal {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    // pointer-events: none;

    &.dim {
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 100;
    }
    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      width: 414px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 56px;
        span {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: center;
          color: $color-black;
        }
      }
      .body {
        height: 768px;
        max-height: calc(100% - 128px);
        .itemList {
          height: 100%;
          overflow-y: scroll;
          .item {
            display: flex;
            align-items: center;
            padding: 16px 27px;
            border-bottom: 1px solid $color-gray7;
            .itemChk {
              margin-right: 16px;
              .chkItem {
              }
            }
            .itemDetail {
              display: flex;
              flex-direction: column;
              gap: 4px 0;
              .itemDesc {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: left;
                color: $color-black;
              }
              .itemTit {
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
                text-align: left;
                color: $color-black;
              }
              .itemDate {
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: normal;
                text-align: left;
                color: $color-gray3;
              }
            }
            &.disabled {
              background-color: $color-gray7;
              .itemChk {
                pointer-events: none;
              }
              .itemDetail {
                .itemDesc {
                  color: $color-gray4;
                }
                .itemTit {
                  color: $color-gray4;
                }
                .itemDate {
                  color: $color-gray4;
                }
              }
            }
          }
        }
      }
      .footer {
        padding: 0 27px 16px 27px;
      }
      .btn_close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 14px;
        height: 14px;
        background: url("../assets/icons/close.svg") center center no-repeat;
        border: none;
        cursor: pointer;
      }
    }
  }
}
