@import "./../styles/variables.scss";

.receipt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 120px 0;

  .logo {
    margin-top: 110px;
    img {
      width: 100%;
    }
  }
  .mainTxt {
    margin-top: 32px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    color: $color-black;
  }
  .subTxt {
    margin-top: 16px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: $color-black;
  }
  .form {
    width: 456px;
    margin-top: 66px;
    padding: 20px 20px 28px 20px;
    background-color: $color-white;
    border: 1px solid $color-gray6;
    .itemCont {
      padding-bottom: 24px;
      border-bottom: 4px solid $color-gray6;
      &:not(:first-child) {
        padding-top: 24px;
      }
      .itemHeader {
        .tit {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.7;
          letter-spacing: normal;
          text-align: left;
          color: $color-black;
        }
      }
      .itemBody {
        margin-top: 16px;
        .itemDesc {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.75;
          letter-spacing: normal;
          text-align: left;
          color: $color-black;
        }
        .itemTitList {
          margin-top: 24px;
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $color-gray6;
            padding: 0 0 12px 0;
            margin: 0 0 12px 0;
            .itemTit {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.75;
              letter-spacing: normal;
              text-align: left;
              color: $color-black;
            }
            .itemDesc {
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.75;
              letter-spacing: normal;
              text-align: right;
              color: $color-black;
            }
          }
        }
        .itemList {
          display: flex;
          flex-direction: column;
          gap: 4px;
          padding-bottom: 12px;
          border-bottom: 1px solid $color-gray7;
          .line {
            display: block;
            padding: 0 0 12px 0;
            border-top: 1px solid $color-gray6;
          }
          &.bgGray {
            margin: 24px 0 4px 0;
            padding: 20px 16px;
            background-color: rgba(44, 71, 98, 0.03);
            border: none;
            gap: 16px 0;
          }
          .item {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .itemTit {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.44;
              letter-spacing: normal;
              text-align: left;
              color: $color-black;
              em {
                display: inline-block;
                font-weight: bold;
                margin-bottom: 2px;
              }
              .subTxt {
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: left;
                color: $color-gray2;
              }
            }
            .itemDesc {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.75;
              letter-spacing: normal;
              text-align: right;
              color: $color-black;
              em {
                font-weight: bold;
              }
            }
          }
        }
        .totalPrice {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 20px;
          .itemTit {
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
          }
          .itemDesc {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: right;
            color: $color-black;
          }
        }
      }
      &.pointItem {
        padding-bottom: 0;
        .itemList {
          border-bottom: none;
        }
      }
    }
    .buttons {
      margin-top: 24px;
      button {
        width: 100%;
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
    }
  }
}
