@import "./../../styles/variables.scss";

.completeWork {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0 0;

  .logo {
    margin-top: 191px;

    img {
      width: 100%;
    }
  }
  .mainTxt {
    margin-top: 32px;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    color: $color-black;

    em {
      font-weight: 700;
    }
  }
  .subTxt {
    margin-top: 16px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: $color-black;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    margin-top: 59px;

    a:first-child {
      margin-bottom: 16px;
    }
  }
}

.sales {
  padding: 60px 0 120px 0;
  .header {
    position: relative;
  }
  .content {
    display: none;
    margin-top: 40px;

    &.visible {
      display: block;
    }

    .frmHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mainTxt {
      }
      .btnNotice {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: right;
        color: $color-black;
        img {
          margin-left: 4px;
        }
      }
    }
    .subTxt {
      margin-top: 8px;
    }
    .frmCont {
      margin-top: 18px;
      .itemCont {
        margin-bottom: 24px;
        .itemHeader {
          .tit {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: $color-gray2;
          }
        }
        .itemBody {
          margin-top: 12px;
          .chkList {
            display: flex;
            flex-wrap: wrap;
            gap: 12px 16px;
            .chkItem {
              span {
                color: $color-gray1;
              }
            }
          }
          .rentalFee {
            margin-top: 24px;
            .rentalFeeItem {
            }
          }
          .radioList {
            display: flex;
            flex-wrap: wrap;
            gap: 12px 16px;
            .radioItem {
              label {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: $color-gray1;
              }
            }
          }
          .linkedModal {
            display: flex;
            flex-direction: column;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            .btnCoupon {
              display: inline-flex;
              justify-content: space-between;
              align-items: center;
              width: 360px;
              height: 48px;
              padding: 0 12px;
              border: 1px solid $color-gray6;
              border-radius: 8px;
              span {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: $color-gray5;
                &.active {
                  color: $color-black;
                }
              }
              img {
              }
            }
          }
        }
      }
    }
  }
  .buttons {
    margin-top: 60px;
  }
}
