@import "./../styles/variables.scss";

.progress-bar {
  width: 100%;
  background-color: $color-gray7;

  .progress {
    background-color: $color-primary;
    height: 4px;
    transition: width 0.85s 0.25s linear;
  }
}
