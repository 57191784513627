@import "./../styles/variables.scss";

.mobileView {
    padding-top: 15%;
    .logo {
        text-align: center;
        > img {

        }
    }
    .guide {
        margin-top: 80px;
        .desc {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            text-align: center;
            color: #000;
        }
        .buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-top: 16px;
            .btn_download {
                cursor: pointer;
                > img {

                }
            }
        }
    }
}