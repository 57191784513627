.input-form {
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &.required {
    .form-title {
      span::after {
        content: "*";
        position: absolute;
        top: -3px;
        margin-left: 4px;
        font-size: 18px;
        font-weight: 700;
        color: #ea5a00;
      }
    }
    .title-sub {
      padding-left: 16px !important;
    }
  }
  .form-title {
    margin-bottom: 12px;
    span {
      position: relative;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: $color-gray2;
    }
    .title-sub {
      position: relative;
      font-size: 12px;
      color: #ea5a00;
      padding-left: 4px;
    }
  }
  .form-field {
    display: flex;
    align-items: center;

    // default input
    .input-element {
      display: flex;
      align-items: center;
      width: 360px;
      height: 48px;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid $color-gray6;
      transition: border-color 0.3s;
      overflow: hidden;
      padding: 0 12px;

      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        font-size: 14px;
        font-weight: 400;
        color: $color-black;
      }
      input::placeholder {
        color: $color-gray5;
      }
      input:focus {
        outline: none;
      }

      &:focus-within {
        border-color: $color-primary;
      }

      // text_notice (안내말)
      &.text_notice {
        input {
          width: auto;
        }
        span {
        }
      }
    }

    // input file
    .file-element {
      position: relative;
      display: flex;
      align-items: center;
      width: 360px;
      height: 48px;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid $color-black;
      transition: border-color 0.3s;
      overflow: hidden;
      padding: 0 12px;

      input[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        border: 0;
      }
      .upload_name {
        color: $color-black;
        z-index: 1;
        display: inline-block; 
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
        max-width: calc(100% - 30px);
      }
      label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        background-color: transparent;
        cursor: pointer;

        img {
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;
        }
      }
    }

    // number element (6 number)
    .number-element {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0 12px;
      width: 360px;
      height: 48px;
      background-color: #fff;
      transition: border-color 0.3s;
      overflow: hidden;
      padding: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        font-size: 14px;
        font-weight: 400;
        color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        opacity: 0;
      }
      input::placeholder {
        color: $color-gray5;
      }
      input:focus {
        outline: none;
      }

      .authKeyItems {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100%;
        border-bottom: 2px solid $color-gray6;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: normal;
        text-align: center;
        color: $color-black;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      &:focus-within {
        border-color: $color-primary;
      }
    }

    // buttons with input
    .btn-address {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 112px;
      height: 48px;
      margin-left: 12px;
      border-radius: 6px;
      background-color: $color-primary;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: $color-black;
    }
    .btn-auth {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 112px;
      height: 48px;
      margin-left: 12px;
      border-radius: 6px;
      background-color: $color-primary;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: $color-black;
    }
    .btn-noncash {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 112px;
      height: 48px;
      margin-left: 12px;
      border-radius: 6px;
      background-color: $color-primary;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: $color-black;
    }
  }

  // auth info & buttons
  .auth-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;

    .remain_time {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: $color-gray1;
    }
    button {
      padding: 4px 16px;
      border: 1px solid $color-gray5;
      background-color: #fff;
      border-radius: 6px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: $color-gray1;
    }
  }

  // disabled
  &.disabled .input-element {
    background-color: $color-gray6;
    border-color: $color-gray6;

    input {
      color: $color-gray4;
      pointer-events: none;
      cursor: auto;
    }
  }
  &.disabled .file-element {
    background-color: $color-gray6;
    border-color: $color-gray6;
    pointer-events: none;

    .upload_name {
      color: $color-gray4;
      pointer-events: none;
      cursor: auto;
    }
    label {
      img {
        display: none;
      }
    }
  }
  &.disabled .btn-address {
    background-color: $color-gray6;
    border-color: $color-gray6;
    color: $color-gray4;
    cursor: default;
    pointer-events: none;
  }

  &.disabled .btn-auth {
    background-color: $color-gray6;
    border-color: $color-gray6;
    color: $color-gray4;
    cursor: default;
    pointer-events: none;
  }

  // alert effects
  &.error .input-element {
    border-color: $color-danger;
  }
  &.success .input-element {
    border-color: $color-susscess;
  }

  .alert-msg {
    display: none;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }
  &.error .alert-msg.error {
    display: block;
    color: $color-danger;
  }
  &.success .alert-msg.success {
    display: block;
    color: $color-susscess;
  }
  &.timer .alert-msg.timer {
    display: block;
    color: $color-gray1;
  }

  // certification button
  .btn-certification {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 64px;
    height: 28px;
    background-color: $color-gray6;
    border: 1px solid $color-gray6;
    border-radius: 4px;
    padding: 0;
    font-size: 12px;
    color: $color-gray4;
    cursor: pointer;

    &.completed {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-black;
    }
    &.certified {
      background-color: $color-white;
      border-color: $color-primary;
      color: $color-black;
    }
  }

  // file image
  .form-image {
    margin-top: 12px;
    .image-item {
      position: relative;
      background-color: #e8e8e8;
      width: 188px;
      height: 188px;
      border-radius: 12px;
      border: 1px solid #e8e8e8;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .image-empty {
      position: relative;
      background-color: #e8e8e8;
      width: 188px;
      height: 188px;
      border-radius: 12px;
      border: 1px solid #e8e8e8;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "";
        width: 24px;
        height: 24px;
        background: url("../../assets/icons/icon-camera.svg");
      }
    }
  }
}

// range
// input[type="range"] {
//   overflow: hidden;
//   height: 30px;
//   -webkit-appearance: none;
//   margin: 10px 0;
//   width: 100%;
//   background: transparent;
// }

// input[type="range"]:focus {
//   outline: none;
// }

// input[type="range"]::-webkit-slider-runnable-track {
//   width: 100%;
//   height: 100%;
//   cursor: pointer;
//   border-radius: 5px;
//   border: 2px solid #ff96ab;
// }

// input[type="range"]::-webkit-slider-thumb {
//   -webkit-appearance: none;
//   width: 30px;
//   height: 30px;
//   background: #ff494f;
//   box-shadow: 1px 1px 7px #d16a6e;
//   cursor: pointer;
//   box-shadow: -100vw 0 0 100vw #ff96ab;
// }
