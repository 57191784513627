@import "./../styles/variables.scss";

.mypage {
  padding: 53px 0 120px 0;
  // 대시보드
  .tabWrapper {
    margin-top: 28px;
    .tabHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      border-bottom: 1px solid $color-gray6;
      button {
        padding-bottom: 15px;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        color: $color-gray5;
        &.active {
          font-weight: bold;
          color: $color-black;
          border-bottom: 1px solid $color-primary;
        }
      }
    }
    .tabContainer {
      margin-top: 52px;
    }
  }
}
